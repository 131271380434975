<template>
  <ion-page
    ><cyan-page-wrapper
      title="Punto Cyan"
      :backRoute="backRoute"
      nav1="PUNTO CYAN"
      :nav2="datosCentro.nombre"
      :ambito="ambito"
      class="puntoCyan"
    >
      <div class="mobileShow">
        <div class="banner nivel1">{{ datosCentro.nombre }}</div>
        <div class="banner nivel2">{{ ambito }}</div>
      </div>

      <ion-button
        v-if="$store.getters.userHasPermission('directorio')"
        @click="abrirDirectorio"
        expand="block"
      >
        <!-- eslint-disable vue/no-deprecated-slot-attribute -->
        <ion-icon slot="start" :icon="helpBuoyOutline"></ion-icon>
        <!-- eslint-enable vue/no-deprecated-slot-attribute -->

        VER DIRECTORIO</ion-button
      >

      <div class="filtro">
        <ion-searchbar
          id="duisearch"
          inputmode="numeric"
          type="numeric"
          v-model="filtroRaw"
          @keyup="checkEnvioFiltro"
          placeholder="Escriba el DUI..."
        >
        </ion-searchbar>
      </div>
      <div class="banner nivel1 gris" v-if="movilizados.length">
        LISTA DE MOVILIZADOS ({{ movilizados.length
        }}{{
          movilizados.length === movilizadosFull.length
            ? ""
            : "/" + movilizadosFull.length
        }})
      </div>

      <div
        v-for="element in movilizados"
        :key="element.id"
        :class="['votante', 'em-' + element.estado_movilizacion]"
        @click="movilizadoClick(element)"
      >
        <ion-row>
          <ion-col size="12" class="nombre">{{ element.nombre }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12" size-md class="dato">
            Posición:
            <span>#{{ element.position }}</span>
          </ion-col>
          <ion-col size="12" size-md class="dato">
            Participó:
            <span>{{
              element["estado_movilizacion"] === "pending"
                ? "Marcando..."
                : element["estado_movilizacion"]
                ? "Sí"
                : "No"
            }}</span>
          </ion-col>
          <ion-col size="12" size-md class="dato">
            DUI:
            <span>{{ element.dui }}</span>
          </ion-col>
        </ion-row>
      </div>
      <div class="banner nivel1 gris" v-if="!movilizados.length">
        <span v-if="!movilizadosFull.length">No hay votantes asignados.</span>
        <span v-else-if="!filtroAplicable"
          >Escriba los 9 dígitos del DUI para buscar a un votante asignado a
          este centro.</span
        >
        <span v-else
          >Ninguno de los votantes de este centro coincide con el filtro
          introducido.</span
        >
      </div>
    </cyan-page-wrapper></ion-page
  >
</template>

<script lang="ts">
import CyanPageWrapper, {
  store,
  defineComponent,
} from "@/components/CyanPageWrapper.vue";

import { municipios } from "@/modules/cyanRegions";

import {
  IonRow,
  IonCol,
  IonPage,
  toastController,
  IonSearchbar,
  IonIcon,
  IonButton,
} from "@ionic/vue";

import cyanRequest from "../modules/cyanRequest";

import { helpBuoyOutline } from "ionicons/icons";

export default defineComponent({
  name: "PuntoCyan",
  components: {
    IonRow,
    IonPage,
    IonCol,
    IonIcon,
    IonButton,
    IonSearchbar,
    CyanPageWrapper,
  },
  setup() {
    return {
      helpBuoyOutline,
    };
  },
  data() {
    return {
      filtroRaw: "",
    };
  },
  computed: {
    filtro(): string {
      const f = this.filtroRaw.replace(/[^0-9]/g, "");
      if (f.length < 9) return f;
      return f.substr(0, 8) + "-" + f.substr(8, 1);
    },
    nivel(): string {
      return (this.$route.params.ambito as string) || "";
    },
    acj() {
      const n = this.nivel as string;
      const acj = store.state.allowedCentrosJuntas[n];
      return acj || {};
    },
    idCentro(): string {
      return (this.$route.params.centro as string) || "-1";
    },

    movilizadosFull() {
      const m = store.state.movilizados[(this as any).idCentro];
      if (typeof m === "undefined" || !m.ok) return [];
      return m.movilizados;
    },

    movilizadosOld() {
      const m = (this as any).movilizadosFull;

      let mf;

      if (this.filtro === "") {
        mf = m.slice();
      } else {
        mf = [];
        const f = this.filtro.toLowerCase();

        if (f.match(/^[0-9]{1,3}$/)) {
          for (const element of m) {
            if (element.position + "" == f) mf.push(element);
          }
        }

        if (!mf.length) {
          for (const element of m) {
            if (
              (element.nombre + "|" + element.dui + "|" + element.position)
                .toLowerCase()
                .includes(this.filtro)
            )
              mf.push(element);
          }
        }
      }

      mf.sort(function (a: any, b: any) {
        if (
          (a.estado_movilizacion === false &&
            b.estado_movilizacion === false) ||
          (a.estado_movilizacion !== false && b.estado_movilizacion !== false)
        ) {
          return a.position - b.position;
        }

        return a.estado_movilizacion === false ? -1 : 1;
      });

      return mf;
    },

    filtroAplicable() {
      return (this as any).filtro.length == 10;
    },

    movilizados() {
      const m = (this as any).movilizadosFull;

      if (!this.filtroAplicable) {
        return [];
      } else {
        const f = (this as any).filtro;

        return m.filter(function (x: any) {
          return x.dui.includes(f);
        });
      }
    },

    ambito() {
      const n = this.nivel as string;
      if (!(n in municipios)) return "";
      return municipios[n].nombre + ", " + municipios[n].nombrePadre;
    },
    backRoute() {
      const n = this.nivel as any;
      const acj = this.acj;
      if (acj && acj.centrosFull && acj.centrosFull.length != 1) {
        return "/puntoPickCentro/" + n;
      }
      if (n.length == 6) {
        return "/home";
      }

      return "/puntoPickMpio/" + n.substr(0, 4);
    },
    datosCentro() {
      const acj = this.acj as any;

      if (!acj || !acj.datosCentros || !acj.datosCentros[this.idCentro])
        return {};

      return acj.datosCentros[(this as any).idCentro];
    },
  },
  watch: {
    filtroRaw: function () {
      if (this.filtroRaw != this.filtro) {
        this.filtroRaw = this.filtro;
        const el = document.getElementById("duisearch") as any;

        if (el) {
          el.value = this.filtro; // bug en ion-input
          if (
            el.children &&
            el.children[0] &&
            el.children[0].tagName == "INPUT"
          )
            el.children[0].value = this.filtro;
        }
      }
    },
  },
  methods: {
    abrirDirectorio() {
      const r = this.$route.path.split("/");
      while (r.length > 4) r.pop();
      r[1] = "dirCentroPC";
      this.$router.replace(r.join("/"));
    },

    telefonoGuay(t: string) {
      return t.substr(0, 4) + "-" + t.substr(4);
    },
    movilizadoClick(m: any) {
      if (m.estado_movilizacion === "pending") return;

      const oldEM = m["estado_movilizacion"];
      m["estado_movilizacion"] = "pending";

      cyanRequest(
        (oldEM ? "unsetMovilizacion" : "setMovilizacion") +
          "/" +
          this.idCentro +
          "/" +
          m.id,
        {
          isPost: true,
          needsAuth: true,
        }
      ).then(function (d: any) {
        let msg = m.nombre + " (DUI " + m.dui + ").";

        if (d.ok) {
          m["estado_movilizacion"] = d["estado_movilizacion"];
          msg =
            "Se " +
            (d["estado_movilizacion"] ? "MARCÓ" : "DESMARCÓ") +
            " como participante a " +
            msg;
        } else {
          m["estado_movilizacion"] = oldEM;
          msg =
            "Hubo un error al actualizar el estado de " +
            msg +
            " Inténtelo de nuevo más tarde.";
        }

        toastController
          .create({
            message: msg,
            duration: 2000,
          })
          .then((toast: any) => toast.present());
      });
    },
    checkEnvioFiltro(e: KeyboardEvent) {
      if (e.keyCode != 13 || this.filtro === "" || this.movilizados.length == 0)
        return;

      this.movilizadoClick(this.movilizados[0]);
      this.filtroRaw = "";
    },
  },
});
</script>

<style scoped>
.nombre {
  font-weight: 500;
  font-size: 120%;
  color: var(--ion-color-primary);
}
.dato {
  color: var(--cyan-dark-grey);
  font-weight: 500;
  padding-left: 1.6em;
  text-indent: -1.6em;
}
.dato span {
  color: var(--ion-color-primary);
  font-weight: normal;
}
.votante {
  margin: 5px 0;
  border: 1px solid var(--cyan-light-grey);
  border-radius: 15px;
  background: white;
  padding: 0 5px;
}

.em-true {
  background: rgb(189, 243, 189);
}

/*.em-false {
    background: rgb(253, 225, 225);
}*/

.em-pending {
  background: rgb(186, 186, 230);
}

.dato.cc-ok {
  color: var(--ion-color-success);
}

.dato.cc-rejected {
  color: var(--ion-color-danger);
}

.dato.cc-pending {
  color: var(--ion-color-medium);
}

.dato ion-icon {
  font-size: 110%;
  padding: 0 0 0 3px;
  position: relative;
  top: 3px;
}
</style>